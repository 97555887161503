import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72')
];

export const server_loads = [];

export const dictionary = {
		"/(console)": [15,[2]],
		"/(console)/(appsmith)/as/qr-center-select": [16,[3]],
		"/(console)/(appsmith)/as/qr-dots-select": [17,[3]],
		"/(console)/(appsmith)/as/qr-edge-select": [18,[3]],
		"/(console)/(appsmith)/as/qr-gen": [19,[3]],
		"/(mobile)/c/404": [36,[10]],
		"/(mobile)/c/accountLocked": [57,[10]],
		"/(mobile)/c/codeOpenLimit": [58,[10]],
		"/(mobile)/c/orderToActivate": [59,[10]],
		"/(mobile)/c/[codeId]": [37,[10,11]],
		"/(mobile)/c/[codeId]/chat": [38,[10,11]],
		"/(mobile)/c/[codeId]/document": [39,[10,11]],
		"/(mobile)/c/[codeId]/hbx/chat": [40,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/checkup/address": [45,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/checkup/history": [46,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/checkup/options": [47,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/checkup/schedule": [48,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/checkup/uploadImages": [49,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/checkup/[checkupId]": [41,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/checkup/[checkupId]/document": [42,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/checkup/[checkupId]/images": [43,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/checkup/[checkupId]/images/imageZoom": [44,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/control/mech/address": [50,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/control/mech/options": [51,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/control/mech/schedule": [52,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/control/mech/uploadImages": [53,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/control/visual": [54,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/docs": [55,[10,11,12]],
		"/(mobile)/c/[codeId]/hbx/history": [56,[10,11,12]],
		"/(mobile)/demo": [60,[10,13]],
		"/(mobile)/demo/e/[id]": [61,[10,13]],
		"/(mobile)/demo/m/[machine]": [62,[10,13,14]],
		"/(mobile)/demo/m/[machine]/action": [63,[10,13,14]],
		"/(mobile)/demo/m/[machine]/doc": [64,[10,13,14]],
		"/(mobile)/demo/m/[machine]/issue": [65,[10,13,14]],
		"/(mobile)/demo/signout": [66,[10,13]],
		"/error-d732gd5sv29b/client/load": [68],
		"/error-d732gd5sv29b/client/load/client-load": [69],
		"/error-d732gd5sv29b/client/page": [70],
		"/error-d732gd5sv29b/server/load": [71],
		"/error-d732gd5sv29b/server/page": [72],
		"/(console)/(demos)/maersk-demo-329875": [21,[2]],
		"/(console)/(appsmith)/navigation-helper": [20,[3]],
		"/(console)/pricing-sliders": [22,[2]],
		"/(console)/project/(top-bar)/(no-project)": [33,[2,4,8]],
		"/(console)/project/(top-bar)/(no-project)/new": [34,[2,4,8]],
		"/(console)/project/(side-bar)/[projectId]": [24,[2,4,5]],
		"/(console)/project/(top-bar)/[projectId]/activateCode/[codeId]": [35,[2,4,9]],
		"/(console)/project/(no-layout)/[projectId]/chat/[conversationId]": [23,[2,4]],
		"/(console)/project/(side-bar)/[projectId]/hbxAssign": [26,[2,4,5]],
		"/(console)/project/(side-bar)/[projectId]/hbxEditProduct/[hbxProductId]": [27,[2,4,5]],
		"/(console)/project/(side-bar)/[projectId]/hbxLab": [28,[2,4,5]],
		"/(console)/project/(side-bar)/[projectId]/hbxLab/[codeId]": [29,[2,4,5]],
		"/(console)/project/(side-bar)/[projectId]/hbxOrder": [30,[2,4,5]],
		"/(console)/project/(side-bar)/[projectId]/hbxOrder/[encOrderId]": [31,[2,4,5,7]],
		"/(console)/project/(side-bar)/[projectId]/hbxOrder/[encOrderId]/add": [32,[2,4,5,7]],
		"/(console)/project/(side-bar)/[projectId]/[subPage]": [25,[2,4,5,6]],
		"/(mobile)/youtube/[ytVideoId]": [67,[10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';