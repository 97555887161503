import * as Sentry from '@sentry/sveltekit'
import { env } from '$env/dynamic/public'

Sentry.init({
  dsn: 'https://f71385bf913948a5acff394a3f2f4d75@o503086.ingest.sentry.io/6260540',

  beforeSend(event) {
    if (env.PUBLIC_ENVIRONMENT === 'prod') return event
    return null
  },

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.0,

  // Optional: Initialize Session Replay:
  // integrations: [Sentry.replayIntegration()],
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
})

export const handleError = Sentry.handleErrorWithSentry()
